<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('deposit_wait.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-form ref="form" v-model="formValid">
        <v-card>
          <v-card-title> {{ $t('deposit_wait.head_list1') }} </v-card-title>
          <v-card-actions>
            <v-autocomplete v-debounce:500ms="searchUser" v-model="memberId"
              :placeholder="$t('deposit_wait.please_select')" dense :items="members" :rules="[v => !!v || '']"
              item-text="label" @change="getCredit" :loading="loadingMember" :disabled="loadingMember"
              outlined></v-autocomplete>
            <v-text-field v-if="typeItem !== 'NO AG'" v-model="waitCredit" readonly
              :label="$t('deposit_wait.credit_wait')" dense outlined />
            <v-select v-if="typeItem !== 'NO AG'" :label="$t('deposit_wait.bank')" v-model="typeBank"
              :rules="[v => !!v || '']" :items="bankItems" :loading="loadingBank" item-text="label" dense outlined />
            <v-select v-if="typeItem === 'YES'" :label="$t('deposit_wait.promotion')" v-model="typePromotion"
              :rules="[v => !!v || '']" :items="promotionItems" :loading="loadingPromotion" item-text="name" dense
              outlined />
            <v-select :label="$t('deposit_wait.type')" v-model="typeItem" :rules="[v => !!v || '']" :items="typeItems"
              item-text="name" @change="changeType" dense outlined />
          </v-card-actions>

          <v-card-actions>
            <v-text-field @keyup="changeType" v-model="amount" :label="$t('deposit_wait.amount')" type="number"
              :rules="[v => !!v || '']" dense outlined />
            <v-text-field v-if="typeItem !== 'NO AG'" v-model="afterAmount" :label="$t('deposit_wait.credit_after')"
              readonly dense outlined />
            <date-picker-input ref="dateCredit" v-if="typeItem === 'YES'" :label="$t('deposit_wait.date')" :date="dateNow"
              @setValue="dateNow = $event" />
            <date-picker-input ref="timeCredit" :time="timeNow" @setValue="timeNow = $event" v-if="typeItem === 'YES'"
              :label="$t('deposit_wait.time')" :showTime="true" />
          </v-card-actions>
          <v-divider class="px-3" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" :loading="loadingSubmit" @click="credit" class="white--text">
              <v-icon small class="px-2">mdi-content-save-outline</v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('deposit_wait.head_list2') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input v-model="valDateStart" ref="startDate" :date="dateFrom"
                :label="$t('deposit_wait.date_from')" @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input v-model="valDateEnd" ref="endDate" :date="dateTo" :label="$t('deposit_wait.date_to')"
                @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" @click="searchDeposit" class=""> {{ $t('search') }} </v-btn>
            <!-- <v-btn color="warning" @click="preloadExportCsv" class="">ออกรายงาน Excel</v-btn> -->
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.currentPage" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="statement">
            <template v-slot:[`item.accountbank`]="{ item }">
              <div class="p-0 d-flex row" style="align-items: center">
                <div class="col-3 px-2 py-0" style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                  <div v-if="!item.bankCountry">
                    <img v-if="item.account_bank" alt="bank_img"
                      style="object-fit: contain;height: inherit;width: 100%;"
                      :src="require(`@/assets/images/logos/banks/${item.account_bank}.png`)" />
                  </div>
                  <div v-else>
                    <img v-if="item.account_bank" alt="bank_img"
                      style="object-fit: contain;height: inherit;width: 100%;"
                      :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.account_bank}.webp`" />
                  </div>
                </div>
                <div class="col-9 pa-0 ma-0 d-flex flex-column">
                  <div class="col pa-0">
                    <p class="detail-bank-p">{{ item.account_name && item.account_name }}</p>
                  </div>
                  <div class="col pa-0">
                    <p class="detail-bank-p">{{ item.account_number && item.account_number }}</p>
                  </div>
                </div>
                </div>
            </template>
            <template v-slot:[`item.before_amount`]="{ item }">
              {{ item.before_amount | currency }}
            </template>
            <!-- <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | currency }}
            </template> -->
            <template v-slot:[`item.total`]="{ item }">
              {{ item.total | currency }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <div class="amount-col">
                <span class="success--text" v-if="item.type"> <v-icon class="success--text">mdi-arrow-up</v-icon></span>
                <span class="error--text" v-else> <v-icon class="error--text">mdi-arrow-down</v-icon></span>
                {{ item.amount | currency }}
              </div>
            </template>
            <template v-slot:[`item.slip_url`]="{ item }">
              <img v-if="item.slip_payment && item.slip_url" class="preview-slip-img" :src="item.slip_url"
                :alt="item.slip_url" @click="displayFullImage(item.slip_url)" />
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      dateNow: moment().format('YY-MM-DD'),
      formValid: true,
      btnl: false,
      date: null,
      currentMemberDetail: {},
      afterCredit: '',
      typeItem: 'YES',
      typeBank: '',
      typePromotion: null,
      waitCredit: '',
      afterAmount: '',
      amount: '',
      timeNow: '00:00:00',
      searchData: null,
      valDateStart: null,
      valDateEnd: null,
      typeItems: [
        {
          name: this.$t('deposit_wait.add_credit'),
          value: 'YES',
        },
        {
          name: this.$t('deposit_wait.decrease_auto'),
          value: 'NO',
        },
        {
          name: this.$t('deposit_wait.decrease_ag'),
          value: 'NO AG',
        },
      ],
      acceptItem: '',
      acceptItems: [
        {
          text: this.$t('yes'),
          value: true,
        },
        {
          text: this.$t('not'),
          value: false,
        },
      ],
      headers: [
        {
          text: this.$t('deposit_wait.header1'),
          value: 'member',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait.header2'),
          value: 'before_amount',
          width: '100px',
          align: 'end',
          sortable: false,
        },
        // {
        //   text: 'ประเภท',
        //   value: 'type',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          text: this.$t('deposit_wait.header3'),
          value: 'amount',
          width: '100px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait.header4'),
          value: 'total',
          width: '100px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait.header5'),
          value: 'admin',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait.header5_2'),
          value: 'slip_url',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait.header6'),
          value: 'accountbank',
          width: '170px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait.header7'),
          value: 'transition_time',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait.header8'),
          value: 'dateUpdate',
          width: '120px',
          align: 'center',
          sortable: false,
        },
      ],
      bankKey: this.$store.getters.getKeyBank,
      statement: [],
      members: [],
      promotionItems: [],
      memberId: '',
      loading: false,
      loadingMember: false,
      loadingSubmit: false,
      loadingBank: false,
      loadingPromotion: false,
      dummy: true,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      flag_interval: null,
      bankItems: [],
      bankSystem: ''
    }
  },
  beforeDestroy() {
    if (this.flag_interval) {
      clearInterval(this.flag_interval);
    }
  },
  async created() {
    this.getWebSettingInfo()
    this.addLogPage()
    this.getAccountBankDeposit()
    this.searchUser()
    this.refreshdata()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate?.value, this.$refs.endDate?.value, this.searchData)
        if (cont && !this.dummy)
          await this.getDepositData(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate?.value,
            this.$refs.endDate?.value,
            this.searchData,
          )
        if (cont && this.dummy)
          await this.getDepositData(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate?.value,
            this.$refs.endDate?.value,
            this.searchData,
          )
      },
      deep: true,
    },
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'รายการรอฝาก',
              url: window.location.href,
              detail: 'ระบบจัดการรายการรอฝาก',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getAccountBankDeposit() {
      this.loadingBank = true

      try {
        const rs = await this.$store.dispatch('getAccountBankDeposit')
        rs.map(el => {
          this.bankItems.push({
            value: el.id,
            label: `${el.bank} | ${el.bank_name} : ${el.bank_account}`,
          })
        })
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }

      this.loadingBank = false
    },
    async searchDeposit() {
      this.dummy = false
      await this.getDepositData(
        this.pagination.page,
        this.pagination.rowsPerPage,
        this.$refs.startDate?.value,
        this.$refs.endDate?.value,
        this.searchData,
      )
      this.pagination.currentPage = 1
    },
    async getDepositData(page, row, time_from, time_to, search) {
      this.valDateStart = this.$refs.startDate?.value
      this.valDateEnd = this.$refs.endDate?.value
      if (page !== 1 || search) {
        clearInterval(this.flag_interval)
      }
      try {
        this.loading = true
        if (this.$route.name === 'credit') {
          let formatDateStart = this.formatDateYYYY(this.valDateStart);
          let formatDateEnd = this.formatDateYYYY(this.valDateEnd);
          const params = {
            page,
            row,
            // status: 'success',
            //credit_to_game: false,
            ...(!search ? { page: page } : {}),
            ...(!search ? { rows: row } : {}),
            ...(page ? { page: page } : {}),
            ...(row ? { rows: row } : {}),
            ...(!search ? { time_from: formatDateStart } : {}),
            ...(!search ? { time_to: formatDateEnd } : {}),
            status: 'success&addbonus',
            ...(search ? { search: search } : {}),
          }
          let rs = await this.$store.dispatch('getDeposit', params)
          this.pagination.totalItems = rs.count
          this.statement = []
          rs.data.forEach(element => {
            let splitCountry, splitBankCode;
            if (element.accountbank && element.accountbank?.bank_code) {
              const code = element.accountbank.bank_code;
              const checkCountryCode = (code) => {
              const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
              const firstTwoChars = code.substring(0, 2);
                return validCodes.includes(firstTwoChars);
              }

              if (checkCountryCode(code)) {
                splitCountry = code.slice(0, 2);
                splitBankCode = code.slice(2);
              } else {
                splitCountry = this.bankSystem;
                splitBankCode = code;
              }

              element.accountbank.bankCountry = splitCountry.toLowerCase();
              element.accountbank.bank = splitBankCode;
            }

            this.statement.push({
              id: element.id,
              amount: element.amount,
              before_amount: element.before_amount,
              type: element.amount > 0 ? true : false,
              // bonus: element.bonus,
              // status: 'success',
              member: element.member ? element.member.phone : null, //phone number
              admin: element.admin ? element.admin.name : 'AUTO',
              // bank: element.accountbank.bank,
              // bank_account: element.accountbank.bank_account,
              date: element.created_at ? moment(element.created_at).format('YY-MM-DD HH:mm:ss') : null,
              dateUpdate: element.updated_at ? moment(element.updated_at).format('YY-MM-DD HH:mm:ss') : null,
              // date: element.created_at ? element.created_at : null,
              total: element.after_amount,
              account_number: element.accountbank?.bank_account ? element.accountbank.bank_account : null,
              account_name: element.accountbank?.bank_name ? element.accountbank.bank_name : null,
              account_bank: element.accountbank?.bank ? element.accountbank.bank : null,
              bankCountry: element.accountbank?.bankCountry ? element.accountbank.bankCountry : null,
              slip_payment: element.slip_payment,
              slip_url: element.slip_url,
              transition_time: element.admin ?
                element.time_transection ? moment(element.time_transection).format('YY-MM-DD HH:mm:ss')
                  : element.transaction_bank ? moment(element.transaction_bank.time).format('YY-MM-DD HH:mm:ss') : ''
                :
                element.transaction_bank ? moment(element.transaction_bank.time).format('YY-MM-DD HH:mm:ss') : ''
            })
          })
        } else {
          clearInterval(this.flag_interval)
        }
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    async searchUser(search) {
      this.loadingMember = true
      let rs = await this.$store.dispatch('getMember', {
        page: 1,
        rows: 25,
        type: 'member',
        ...(search ? { search: search } : {}),
      })
      if (rs.count > 0) {
        this.members = []
        rs.data.forEach(element => {
          this.members.push({
            value: element.id,
            label: `${this.bankKey[element.bank] ? this.bankKey[element.bank] : element.bank} ${element.bank_account
              } : ${element.phone} - ${element.name}`,
          })
        })
      }
      this.loadingMember = false
    },
    async credit() {
      this.loadingSubmit = true
      if (this.$refs.form.validate()) {
        const amountConv = this.typeItem === 'YES' ? parseFloat(this.amount) : -parseFloat(this.amount)
        let params = {
          amount: amountConv,
          ...(this.typeItem === 'YES' ? { save_transtion: true } : { save_transtion: false }),
          ...(this.typeItem === 'YES'
            ? { time_transtion: `${this.formatDateYYYY(this.$refs.dateCredit.value)} ${this.timeNow}:00` }
            : {}),
          ...(this.typeBank ? { accountbank_id: this.typeBank } : {}),
          member_id: this.memberId,
          promotion_id: this.typePromotion,
          type: this.typeItem
        }

        if (this.amount >= 100000) {
          this.$swal.fire({
            icon: 'warning',
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            title: this.$t('deposit_wait.confirm_1') + amountConv.toLocaleString() + this.$t('deposit_wait.confirm_2'),
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await this.$store.dispatch('addDeposit', params)
                await this.getDepositData(this.pagination.page, this.pagination.itemsPerPage)
                this.$swal.fire(this.$t('deposit_wait.deposit_complete'), '', 'success')
                this.memberId = ''
                this.typeItem = ''
                this.waitCredit = ''
                this.amount = ''
                this.afterAmount = ''
              } catch (e) {
                if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
                  this.$swal.fire({
                    icon: 'error',
                    title: e.message,
                  })
                }
              }
            }
          })
        } else {
          try {
            await this.$store.dispatch('addDeposit', params)
            await this.getDepositData(this.pagination.page, this.pagination.itemsPerPage)
            this.$swal.fire(this.$t('deposit_wait.deposit_complete'), '', 'success')
            this.memberId = ''
            this.typeItem = ''
            this.waitCredit = ''
            this.amount = ''
            this.afterAmount = ''
          } catch (e) {
            if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
              this.$swal.fire({
                icon: 'error',
                title: e.message,
              })
            }
          }
        }

      }
      this.loadingSubmit = false
    },
    async getCredit() {
      if (this.memberId) {
        let rs = await this.$store.dispatch('getMemberWallet', this.memberId)
        this.waitCredit = rs.filter(wallet => wallet.wallet === 'MAIN')[0].balance
        this.getPromotionUse(this.memberId)
        this.changeType()
      } else {
        this.promotionItems = [];
        this.afterAmount = 0;
        this.typeItem = 'YES'
      }
    },
    async changeType() {
      if (this.typeItem === 'YES') {
        this.afterAmount = parseFloat(this.waitCredit) + parseFloat(this.amount)
      } else if (this.typeItem === 'NO') {
        this.afterAmount = -(parseFloat(this.amount) - parseFloat(this.waitCredit))
      } else {
        this.afterAmount = null
      }
      if (isNaN(this.afterAmount)) {
        this.afterAmount = 0
      }
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getDepositData(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.currentPage = 1
    },

    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData;
      const userSystem = store.getters.getuserInfo
      await fetch("https://jsonip.com/").then(res => res.json().then(el => (
        logExportData = {
          ip: el.ip,
          exportPage: 'รายการรอฝาก - ระบบจัดการรายการรอฝาก',
          url: window.location.href,
          detail: 'ระบบจัดการรายการรอฝาก',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }
      )))

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if (resEnterSecureData && resEnterSecureData.secure) {
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv() {
      this.$swal.fire({
        html: this.$t("processing"),
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      let fileName = `${this.$t('deposit_wait.export_report_name')}_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      let statementlist = []
      const params = {
        page: 1,
        row: 1000000,
        ...(this.$refs.startDate.value ? { time_from: this.formatDateYYYY(this.$refs.startDate.value) } : {}),
        ...(this.$refs.endDate.value ? { time_to: this.formatDateYYYY(this.$refs.endDate.value) } : {}),
        ...(this.searchData ? { search: this.searchData } : {}),
        status: 'success',
      }
      let rs = await this.$store.dispatch('getDeposit', params)
      rs.data.forEach(element => {
        statementlist.push({
          member: element.member ? String(element.member.phone) : null,
          before_amount: element.before_amount,
          type: element.amount > 0 ? this.$t('increase') : this.$t('decrease'),
          amount: element.amount,
          total: element.after_amount,
          admin: element.admin ? element.admin.name : 'AUTO',
          bank_name: element.accountbank?.bank_name
            ? element.accountbank.bank + '  ' + element.accountbank.bank_name
            : null,
          bank_account: element.accountbank?.bank_account ? element.accountbank.bank_account : null,
          date_transection: element.admin ?
            element.time_transection ? moment(element.time_transection).format('YY-MM-DD HH:mm:ss')
              : element.transaction_bank ? moment(element.transaction_bank.time).format('YY-MM-DD HH:mm:ss') : ''
            :
            element.transaction_bank ? moment(element.transaction_bank.time).format('YY-MM-DD HH:mm:ss') : '',
          date_auto_manual: element.updated_at ? moment(element.updated_at).format('YYYY-MM-DD HH:mm') : '',
        })
      })
      let ws = XLSX.utils.json_to_sheet(statementlist)
      ws['!cols'] = wscols
      const wb = { Sheets: { DepositReport: ws }, SheetNames: ['DepositReport'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, fileName + fileExtension)

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            exportPage: 'รายการรอฝาก',
            url: window.location.href,
            detail: 'ระบบจัดการรายการรอฝาก',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogExportCSV', data)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },

    refreshdata() {
      this.flag_interval = setInterval(() => {
        if (this.$refs.startDate?.value && this.$refs.startDate?.value) {
          this.getDepositData(1, this.pagination.rowsPerPage,)
        }
      }, 180000)
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    async getPromotionUse(memberId) {
      this.loadingPromotion = true

      try {
        const rs = await this.$store.dispatch('getPromotionUse', memberId)
        let items = []
        rs && rs.length > 0 && rs.forEach(el => {
          items.push({
            name: el.name,
            value: el.id
          })
        })
        this.promotionItems = items
        items.length === 1 && (this.typePromotion = items[0].value)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }

      this.loadingPromotion = false
    },
    displayFullImage(image) {
      this.$swal.fire({
        imageUrl: image,
        imageAlt: image || 'Slip image',
      })
    }
  },
}
</script>
<style scoped>
.img-bank {
  margin-top: 0px;
  padding: 0 !important;
}

.detail-bank {
  margin-top: 0px;
  padding: 0 !important;
}

.detail-bank-p {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  text-align: left;
}

.amount-col {
  display: flex;
  justify-content: space-between;
}

.amount-col span i {
  position: absolute;
  margin-left: 30px;
}

@media (max-width: 1400px) {
  .amount-col span i {
    position: relative;
    margin-left: 0 !important;
  }
}

@media (max-width: 550px) {
  .img-bank {
    margin-top: 20px;
  }

  .detail-bank {
    margin-top: 0px;
    padding-bottom: 15px !important;
  }

  .detail-bank-p {
    text-align: center;
  }

  .amount-col span i {
    position: relative;
    margin-left: 0;
  }
}

.preview-slip-img {
  width: 56px;
  height: 56px;
  object-fit: fill;
  cursor: pointer;
}
</style>
